import React from 'react';
import Markdown from 'react-markdown';
import { graphql } from 'gatsby';
import { Layout, Section } from 'components';

export const query = graphql`
  query AnnouncementQuery($strapiId: Int!) {
    strapiAnnouncements(strapiId: { eq: $strapiId }) {
      title
      content
    }
  }
`;

const Announcement = ({ data }) => {
  const announcement = data.strapiAnnouncements;

  return (
    <Layout>
      <div className='uk-padding-large'></div>
      <Section>
        <div className='uk-grid'>
          <div className='uk-width-2-3@m'>
            <div className='uk-flex uk-flex-middle'>
              <div className='uk-flex uk-flex-column'>
                <div className='uk-h2 uk-text-bold'>{announcement.title}</div>
                <div className='uk-h5'>
                  <Markdown source={announcement.content} escapeHtml={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default Announcement;
